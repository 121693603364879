.app__experience {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__skills-exp {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  
    @media screen and (max-width: 900px) {
      margin-top: 2rem;
    }
}
  
.app__skills-exp-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;
    @media screen and (min-width: 600px) {
        flex-direction: row;
    }
}
  
.app__skills-exp-works {
    flex: 1;
  
    .app__skills-exp-work {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1rem;
      cursor: pointer;
  
      ul {
        max-width: 550px;
      }
      h4 {
        font-weight: 500;
      }
  
      p {
        font-weight: 400;
        color: var(--gray-color);
        margin-top: 5px;
      }
    }
}
  
.app__skills-exp-year {
    width: 15rem;
    margin-right: 3rem;
  
    p {
      font-weight: 800;
      color: var(--secondary-color);
    }
  
    @media screen and (max-width: 450px) {
      margin-right: 1rem;
    }
}

.app__skills-exp-resume {
    cursor: pointer;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid;  
    border-color: var(--secondary-color);
    border-radius: 8px;
    transition: all 0.3s ease-in-out; 

    a {
        color: inherit;
        outline: none;
        text-decoration: none;
        div {
            width: 250px;
            padding: 0.5rem;
            p { 
                color: var(--gray-color);
                transition: all 0.3s ease-in-out;
                text-align: center;
                font-size: 25px;
                text-transform: uppercase;
                font-family: 'monserrat';
            }
        }
    }

    &:hover {
        background-color: var(--secondary-color);
        a {
          div {
            p {
                color: var(--white-color);
            }
          }
        }
    }
}
